import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d7a4604"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary py-2 fw-bold fs-5 w-275px",
  "data-kt-menu": "true",
  style: {"background-color":"#393840 !important"}
}
const _hoisted_2 = { class: "menu-item px-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signOut())),
        class: "menu-link menuLogout fs-base text-white"
      }, [
        _createVNode(_component_inline_svg, {
          style: {"-webkit-transform":"rotate(180deg)","-moz-transform":"rotate(180deg)","-ms-transform":"rotate(180deg)","-o-transform":"rotate(180deg)","transform":"rotate(180deg)"},
          src: "media/icons/iconsax/logout.svg"
        }),
        _createTextVNode("    Cerrar Sesión ")
      ])
    ])
  ]))
}